/* general */

input  {
  border:1px solid darkgray !important;
}

select {
  border:1px solid darkgray !important;
}

option:disabled {
  color: gainsboro;
}

.btn-primary {
  background: #408CC7 !important;
  border-color: #408CC7 !important;
}

/*
 thumbnail img
*/

.thumbnail-img {
  cursor: pointer;
}

/*
 thumbnail img
*/

.card {
  box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.1)  !important
  /* border: 2px solid #408CC7 !important; */
}

.card-header {
  background-color: rgb(64, 140, 199) !important;
  color: white !important;
  font-weight: bold !important;
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.card-header-gray {
  background-color: white !important;
  color: black !important;
}

/*
  modal
*/
.modal-row{
  margin: 10px  !important;
}

.carousel-indicators li {
  filter: invert(1);
}

.carousel-control-next-icon, .carousel-control-prev-icon { 
  filter: invert(1); 
}

/* 
 modal 40% view
*/
.modal-width {
  width: 40vw !important;
  max-width: none !important;
}
/*
  pagination
*/
.page-link {
    background-color: rgba(255,255,255, 0) !important;
    color: #408CC7 !important;
    min-width: 45px;
    text-align: center;
    font-weight: bold;
}

.page-item.active .page-link {
  color: white !important;
  background-color: #408CC7 !important;
  border-color:  #408CC7 !important;
  min-width: 45px;
  text-align: center;
  font-weight: bold;
}

.pagination {
  margin-bottom: 0;
}

.paginationItemVisitedLink {
  background:
    linear-gradient(to top left,
      rgba(169, 169, 169, 0) 0%,
      rgba(169, 169, 169, 0) calc(50% - 2px),
      rgba(169, 169, 169, 1) 50%,
      rgba(169, 169, 169, 0) calc(50% + 2px),
      rgba(169, 169, 169, 0) 100%),
    linear-gradient(to top right,
      rgba(169, 169, 169, 0) 0%,
      rgba(169, 169, 169, 0) calc(50% - 2px),
      rgba(169, 169, 169, 1) 50%,
      rgba(169, 169, 169, 0) calc(50% + 2px),
      rgba(169, 169, 169, 0) 100%);
}

/*
 List Card
*/
.list-card{
  max-height: 300px;
  overflow-y:auto;
}

/* 
  table-striped = the table has data
  Set the background of its heading to darkgray
*/
.table-striped {
  border-top: 3px solid darkgray;
}

.table-striped th {
  background-color: darkgray;
  color: white;
}

.table-selected {
  background-color: yellow;
}

.table-dark a {
  color: #C6C5E8;
}

.table-dark a:hover {
  color: white;
}

.table-td-xs {
  padding: 0.1rem !important;
  font-size: 0.875em;
}

/* log in page */
/* .loginbackground  {
  background-image: url(./img/login_background.png);
} */

html,
body {
  height: 100%;
  margin: 0;
  overflow-x: hidden;
}

header {
  height: 100px;
  background: white;
}

section {
  height: 750px;
  background-color: #3674AB;
}

footer {
  height: calc(100vh-100-750);
  background-color: white;
}

.cn {
  display: flex;
  justify-content: center;
  align-items: center; 
}

/* css for Top Navigation Bar */

.top-navlink {
  height: 40px; 
  padding-top:7px;  
  text-decoration: none; 
  width: 50%; 
  background: black; 
  color: white; 
  margin-right: 2px; 
  text-align: center;
}

.top-navlink:hover {
  text-decoration: none;  
  color: white; 
}

.top-navlink[aria-current="page"] {  
  background: #00aff0 !important;  
}

.top-navlink-last {
  margin-right: 0px !important;
}

div.form-inline-block {
  display: inline-block;
}

/* css for Side Navigation */
.side-nav-header {
  width: -webkit-calc(100% - 2px) !important;
  width:    -moz-calc(100% - 2px) !important;
  width:         calc(100% - 2px) !important;
 
}

.side-navlink {
  width: -webkit-calc(100% - 2px);
  width:    -moz-calc(100% - 2px);
  width:         calc(100% - 2px);
  background: darkgray;
  color: white;
  text-decoration: none; 
  padding-left: 8px; 
  padding-right: 4px; 
  padding-top: 1px;
  padding-bottom: 1px;
}

.side-numbered-navlink-last {
  border-bottom-left-radius: 5px ;
  border-bottom-right-radius: 5px ;
}

.side-navlink:hover {
  text-decoration: none;  
  color: white; 
}

.side-navlink[aria-current="page"] {  
  color: #3674AB !important;  
}

.side-navlink-selected {  
  color: #3674AB !important;  
  background: lightblue !important;
}

.side-numbered-navlink {
  width: -webkit-calc(100% - 2px);
  width:    -moz-calc(100% - 2px);
  width:         calc(100% - 2px);
  background: darkgray;
  color: white;
  text-decoration: none; 
  padding-left: 2px; 
  padding-right: 4px; 
  padding-top: 1px;
  padding-bottom: 1px;
}

.side-numbered-navlink:hover {
  text-decoration: none;  
  color: white; 
}

.side-navlink-icon {
  color: white;
}

.side-navlink-icon-active {
  color: #3674AB;
}

.search-results-sort-dropdown {
  margin-bottom: 0px !important;
  float: right;
}

.search-results-details-toolbar {
  margin-bottom: 0px !important;
  float: right;
}

.bounce {
  -moz-animation: bounce 2s ;
  -webkit-animation: bounce 2s ;
  animation: bounce 2s ;
}

@-moz-keyframes bounce {
  0%,  100% {
    -moz-transform: translateX(0);
    transform: translateX(0);
  }
  50% {
    -moz-transform: translateX(30px);
    transform: translateX(30px);
  }
}

@-webkit-keyframes bounce {
  0%, 100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  50% {
    -webkit-transform: translateX(30px);
    transform: translateX(30px);
  }
}

@keyframes bounce {
  0%, 100% {
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  50% {
    -moz-transform: translateX(30px);
    -ms-transform: translateX(30px);
    -webkit-transform: translateX(30px);
    transform: translateX(30px);
  }
}

/* Image zooming */
figure.preview {
  /* width: 600px; */
  background-repeat: no-repeat;
  width: calc(72px * 2);
  height: calc(72px * 3);
}

figure.preview:hover img {
  opacity: 0;
}

figure.bigPreview {
  background-repeat: no-repeat;
  width:100%;
  height:100%;
}

figure.bigPreview:hover img {
  opacity: 0;
}

.zoomerDialog {
  width: 500px !important;
  max-width: 500px !important;
  min-width: 500px !important;
  height: 750px !important;
  max-height: 750px !important;
}
